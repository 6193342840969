// composables
import { useCookies } from '@vueuse/integrations/useCookies';

import services from '../../services.cligenerated.json';
import { env } from '../../env.cligenerated.json';

export default () => {
  const cookiePrefix = env === 'prod' ? '' : `${env}-`
  const cookies = useCookies([`${cookiePrefix}access-token`, `${cookiePrefix}refresh-token`]);

  const redirectToAuthUi = () => {
    const redirectUrl = window.location;

    window.location = process.env.NODE_ENV === 'production' 
      ? `https://${services['auth']}?redirect_url=${redirectUrl}` 
      : `http://localhost:8080?redirect_url=${redirectUrl}`
  };

  const refreshAccessToken = async (refreshToken = null) => {
    try {
      if(!refreshToken) {
        throw new Error('Unauthorized');
      }

      const response = await fetch(`https://${services['auth-api']}/sign-in?type=refresh_token`, {
        method: 'POST',
        body: JSON.stringify({ refreshToken })
      });

      if(response.status === 401) {
        throw new Error('Unauthorized');
      }

      const { 
        accessToken: newAccessToken,
        refreshToken: newRefreshToken 
      } = await response.json();

      const isDev = process.env.NODE_ENV === 'development';

      // prod env does not have prefix
      const cookiePrefix = env !== 'prod' ? `${env}-` : '';

      // TODO move this logic to separate library
      document.cookie = `${cookiePrefix}access-token=${newAccessToken.value};max-age=${newAccessToken.maxAge};path=${newAccessToken.path ?? ''};sameSite=${newAccessToken.sameSite ?? 'none'};secure=${newAccessToken.secure ?? false};domain=${isDev ? 'localhost' : newAccessToken.domain}`;
      document.cookie = `${cookiePrefix}refresh-token=${newRefreshToken.value};max-age=${newRefreshToken.maxAge};path=${newRefreshToken.path ?? ''};sameSite=${newRefreshToken.sameSite ?? 'none'};secure=${newRefreshToken.secure ?? false};domain=${isDev ? 'localhost' : newRefreshToken.domain}`;
    } catch(e) {
      console.error(e);

      return redirectToAuthUi();
    }
  };

  const fetchWithAuth = async (url, options = {}, isSecondTry = false) => {
    try {
      const cookiePrefix = env === 'prod' ? '' : `${env}-`
      if(!cookies.get(`${cookiePrefix}access-token`)) {
        throw new Error('Should refresh token')
      }
      
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': `Bearer ${cookies.get(`${cookiePrefix}access-token`)}`,
        }
      });

      if(response.status === 403) {
        throw new Error("Unauthorized");
      }

      return response.json();
    } catch(e) {
      if(!isSecondTry) {
        const refreshToken = cookies.get(`${cookiePrefix}refresh-token`);

        await refreshAccessToken(refreshToken);
        
        return fetchWithAuth(url, options, true);
      } else {
        console.log('redirect to auth ui');

        return redirectToAuthUi();
      }
    }
  };

  return {
    fetchWithAuth
  }
};
