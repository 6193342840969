import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import Cookies from 'js-cookie'; 

import services from '../../services.cligenerated.json';
import { env } from '../../env.cligenerated.json';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const cookiePrefix = env === 'prod' ? '' : `${env}-`
  const refreshToken = Cookies.get(`${cookiePrefix}refresh-token`);

  if (!refreshToken) {
    const redirectUrl = window.location;

    window.location = process.env.NODE_ENV === 'production' 
      ? `https://${services['auth']}?redirect_url=${redirectUrl}` 
      : `http://localhost:8080?redirect_url=${redirectUrl}`
  } else {
    next();
  }
})

export default router
